import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private auths: AuthService, private router: Router) {}
  canActivate(): Observable<boolean | UrlTree> {
    if (this.auths.token) {
      return of(this.router.parseUrl('/cuscare'));
    } else {
      return of(true);
    }
  }
}
