import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { AuthResolver } from './auth/auth.resolver';
import { CuscareComponent } from './cuscare/cuscare.component';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './login/login.guard';

const routes: Routes = [
  { path: '', redirectTo: 'cuscare', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  {
    path: 'cuscare',
    loadChildren: () =>
      import('./cuscare/cuscare.module').then((mod) => mod.CuscareModule),
    component: CuscareComponent,
    canActivate: [AuthGuard],
    resolve: { agent: AuthResolver },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
