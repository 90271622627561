<div class="login" [class.loading]="loading">
  <div class="login-header">
    <mat-icon svgIcon="logo-full"></mat-icon>
    <div class="baseline">
      <div class="baseline-empty"></div>
      <span>Financial</span>
      <div class="baseline-line"></div>
    </div>
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="onSumbit()">
    <div class="inputs">
      <div class="input-holder">
        <input
          id="login"
          placeholder="Login"
          type="text"
          formControlName="login"
          (click)="resetError()"
        />
        <div class="input-error">{{ error.login }}</div>
      </div>
      <div class="input-holder">
        <input
          id="password"
          type="password"
          placeholder="Password"
          formControlName="password"
          (click)="resetError()"
        />
        <div class="input-error">{{ error.password }}</div>
      </div>
      <!-- <div class="input-holder checkbox" (click)="changeChecked()">
        <div class="remember">
          <div class="state" [class.checked]="remember"></div>
        </div>
        <div class="me" [class.checked]="remember">Remember me</div>
      </div> -->
    </div>
    <div class="inputs-bottom">
      <button
        class="submit"
        [class.wrong]="wrongCredential"
        type="submit"
        [disabled]="loading"
      >
        <ng-container *ngIf="!loading">LOGIN</ng-container>
        <ng-container *ngIf="loading">CONNECTION</ng-container>
      </button>
      <div class="links">
        <a href="https://www.dv-access.com" target="_blanck"
          >Forgot password ?</a
        >
      </div>
    </div>
  </form>
</div>
<footer>
  <div class="byDV">by Digital Virgo</div>
  <div class="loader-bar">
    <div class="loader-bar-moving" [class.loading]="loading"></div>
  </div>
</footer>
