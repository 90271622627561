import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
export interface ResponseResult {
  status: string;
  code: number;
  data: {token: string};
  message?: string;
}
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  agentAPI = environment.API.agent;
  cuscareAPI = environment.API.cuscare;
  constructor(private http: HttpClient, private authService: AuthService) {}

  login(username: string, password: string): Observable<string> {
    const body = { _username: username, _password: password };
    return this.http
      .post<ResponseResult>(`${this.cuscareAPI}/api/login/token`, body)
      .pipe(map(({ data }) => (this.authService.token = data?.token)));
  }
}
