import { Injectable } from '@angular/core';
import {
  Resolve,
} from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../shared/interfaces/user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthResolver implements Resolve<User> {
  constructor(private authService: AuthService) {}

  resolve(
  ): Observable<User> {
    return this.authService.userInfo();
  }
}
