import { Component } from '@angular/core';

@Component({
  selector: 'app-cuscare',
  templateUrl: './cuscare.component.html',
  styleUrls: ['./cuscare.component.scss'],
})
export class CuscareComponent {
  // constructor(
  // ) {
    
}
