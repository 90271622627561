import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../shared/interfaces/user';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  data$ = this.route.data as Observable<{ agent: User }>;
  agent: User | null = null;
  constructor(
    private auths: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.data$.subscribe((data) => (this.agent = data.agent));
  }

  disconnect() {
    this.auths.clearToken();
    this.router.navigate(['/login']);
  }
}
