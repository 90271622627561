import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'SmartCustCare';

  constructor(sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
    iconRegistry
      .addSvgIcon(
        'logo-full',
        sanitizer.bypassSecurityTrustResourceUrl('assets/logo-full.svg')
      )
      .addSvgIcon(
        'logo-icon',
        sanitizer.bypassSecurityTrustResourceUrl('assets/logo-icon.svg')
      );
  }
}
