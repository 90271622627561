import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { User } from '../shared/interfaces/user';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  cuscareAPI = environment.API.cuscare;
  agentID: number | null = null;

  constructor(private http: HttpClient) {}

  get token(): string {
    return sessionStorage.getItem('SCCToken') || '';
  }

  set token(token: string) {
    sessionStorage.setItem('SCCToken', token);
  }

  clearToken() {
    sessionStorage.removeItem('SCCToken');
  }

  userInfo(): Observable<User> {
    const jwtDecode = jwt_decode(this.token) as User;
    this.agentID = jwtDecode.id;
    return this.http
      .get<{ object: User }>(`${this.cuscareAPI}/api/user/${this.agentID}`)
      .pipe(map(({ object }) => object));
  }
}
