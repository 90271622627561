import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private auths: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const headers = new HttpHeaders({
      'content-type': request.headers.get('content-type') || 'application/json',
      Authorization: 'Bearer ' + this.auths.token,
    });

    const authReq = request.clone({
      headers,
    });
    return next.handle(authReq);
  }
}
