<div class="side left">
  <mat-icon svgIcon="logo-icon"></mat-icon>
  <div>
    <div class="headline"><span class="bold">SMART</span> CUSCARE</div>
    <div class="baseline">Financial Dashboard</div>
  </div>
</div>
<div class="side right">
  <div class="agent">{{ agent?.name }}</div>
  <mat-icon (click)="disconnect()">logout</mat-icon>
</div>
