import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  loginForm;
  wrongCredential = false;
  error: { login: string | null; password: string | null } = {
    login: null,
    password: null,
  };
  errorMessage = null;
  showPassword = false;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router
  ) {
    this.loginForm = this.formBuilder.group({
      login: [null, [Validators.required, Validators.minLength(1)]],
      password: [null, [Validators.required, Validators.minLength(4)]],
    });
  }

  ngOnDestroy() {
    this.loading = false;
  }

  onSumbit() {
    const { login, password } = this.loginForm.value;
    if(login && password){
      this.loginService
      .login(login, password)
      .pipe(
        catchError((err) => {
          this.loading = false;
          this.error.login = 'Invalid username';
          this.error.password = 'Invalid password';
          return of(err);
        })
      )
      .subscribe(() => {
        this.router.navigate(['/cuscare']);
      });
    }else{
      throw new Error('En error happened');
    }
   
  }
  showHidePassword() {
    this.showPassword = !this.showPassword;
  }
  resetError() {
    if (this.error) {
      this.error.login = null;
      this.error.password = null;
    }
  }
}
